#canvas-editor {
    border: 2px lightgray dashed;
    background-size: cover;
    background-color: white;
    background-position: center;
}

.react-tags {
    border: 1px solid lightgray;
    background-color: white;
    margin: 1em 0;
    padding: 0.2em;
    /*display: inline;*/
}
.react-tags__selected {
    display: inline;
}
.react-tags__selected .badge {
    margin-bottom: 3px;
    padding-top:0.5em;
    padding-bottom: 0.5em;
}


.react-tags__search {
    display: inline-block;
}

.react-tags__search-wrapper {
    display: inline-block;
    /*padding-top: 6px;*/
}
.react-tags input {
    border: none;
    text-indent: 1em;
    width: auto !important;
    /*padding-top: 12px;*/
    /*height:100%;*/
    /*margin-top: 12px;*/
}
.react-tags input:focus {
    border: none;
    outline: none;
}

.react-tags .delete-tag {
    font-weight: bolder;
    cursor: pointer;
}

#steps-container {
    background-color: white;
    padding: 1em;
    margin-top: 1em;
}

#undo-img {
    height:3em;
    margin-top:1em;
    margin-right: 2em;
}

#upload-cover-photo {
    background-color: lightgray;
    border-radius: 0;
    width: 100%;
}

.form-label[for="formCategories"] {
    background-color: lightgray;
    width: 100%;
    padding: 0.5em;
}