#artwork-list .card .card-header img {

}

.artwork-list-item {
    font-size: 12px;
}
.artwork-list-item .name {
    margin-bottom: 8px;
}

.artwork-list-item .categories {
    margin-bottom: 0;
    max-width: 100%
}

.artwork-list-item .tags {
    margin-bottom: 8px;
    max-width: 100%;
}
.artwork-list-item .status {
    margin-bottom: 0;;
}

.artwork-list-item .flex-row, .artwork-list-item .card-block.flex-column {
    min-width: 0;
}

.tutorial-cover-image {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;

    /*height: 120px;*/
    /*width: 120px;*/
    width: 120px !important;
    min-width: 120px;
    height: 120px !important;
    min-height: 120px;
}

.tutorial-cover-image img {
    position: absolute;
}

span.action {
    cursor: pointer;
}
span.action:hover {
    text-decoration: underline;
}

span.spinner-border {
    width: 1em;
    height: 1em;
}


.category-list-item {
    min-height: 3em;
}