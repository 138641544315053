@media (max-width: 767.98px) {
    #login-container {
        background-color: #77d3e0;
    }
}

@media (min-width: 768px) {
    #login-container {
        background-color: #f3f3f3;
    }
}

#login-container #login-container-inner {
    background-color: #77d3e0;
    padding: 1em;
    padding-bottom: 4em;
}

#login-col .invalid-feedback {
    font-size: 1.1em;
    line-height: 1em;
    height: 1em;
}

#login-col p {
    font-size: 1.1em;
    line-height: 1em;
}
#login-col .form-group {
    margin-bottom: 1em;
    padding-left: 6em;
    padding-right: 6em;
}


#login-col input {
    border-radius: 0;
    font-size: 1.5em;
}

#login-col form {
    /*max-width: 70%;*/
}

#login-col img {
    width: 80%;
    margin-bottom: 2.5em;
}

#login-col h2,#login-col h3 {
    color: white;
    margin-bottom: 1em;
}

#login-col button {
    font-size:1.5em;
    width: 200px;
    filter: drop-shadow(0px 2px 4px #000) !important;
}
