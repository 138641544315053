
/* questrial-regular - latin */
@font-face {
  font-family: 'Questrial';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/questrial-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Questrial Regular'), local('Questrial-Regular'),
  url('./fonts/questrial-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./fonts/questrial-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./fonts/questrial-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./fonts/questrial-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./fonts/questrial-v10-latin-regular.svg#Questrial') format('svg'); /* Legacy iOS */
}

body {
  font-family: 'Questrial', Fallback, sans-serif !important;
}

.navbar {
  background-color: #77d3e0;
  margin-bottom: 3em;
}

.navbar.sticky-bottom,.navbar.fixed-bottom {
  margin-bottom: 0;
  background-color: white;
  border-top: 1px solid lightgray;
}

.main-container {
  background-color: #f3f3f3;
  padding: 0 !important;
  padding-bottom: 5em !important;
}

@media (max-width: 767.98px) {
  .main-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 768px) {
  .main-content {
    padding-left: 3em;
    padding-right: 3em;
  }

}

.card {
  border: none !important;
  border-radius: 0 !important;
}

.btn.draweroo-btn {
  background-color: #a54c78;
  border-color: #a54c78;
  border-radius: 2em;
  padding: 0.75em 2em;
  margin-top:1em;
  color: white;
}
.btn.draweroo-btn:focus,.btn.draweroo-btn:hover {
  background-color: #a54c78;
  border-color: #a54c78;
}
.btn.draweroo-btn:disabled {
  background-color: lightgray;
  border-color: lightgray;
  color: darkgray;
}

.draweroo-btn.btn-cta {
  background-color: #3197a7;
  border-color: #3197a7;
}

.btn-link {
  cursor: pointer;
}

.Toastify {
  display: inline;
}
